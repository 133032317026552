<template>
    <div class="container padding-container">
        <el-button class="topBtns" type="primary" icon="el-icon-plus" @click="routerChange(1)">新增轮播图</el-button>
        <div class="border-container">
            <el-row class="search-container" type="flex" align="middle" justify="space-between">
                <el-col :lg="2" :sm="4" :xs="8">
                    <span class="list-title">轮播图列表</span>
                </el-col>
                <!-- <el-col :lg="22" :sm="20" :xs="16" class="search-area">
                    <el-col :span='6' class="search-item">
                        
                    </el-col>
                    
                </el-col> -->
            </el-row>

            <div class="table-container">
                <el-table :data="tableData" tooltip-effect="dark" style="width: 100%" size="medium"
                    :header-cell-style="{height: '40px', background: 'linear-gradient(180deg,rgba(251,252,253,1) 0%,rgba(246,248,250,1) 100%)'}"
                    :cell-style="{height: '50px',padding: '5px 0'}" header-align="center">
                    <el-table-column prop="title" label="轮播图名称" min-width="80" :show-overflow-tooltip="true">
                    </el-table-column>
                    <el-table-column prop="image" label="图片" min-width="155" align="center">
                        <template slot-scope="scope">
                            <el-image style="width: 40px; height: 40px; padding: 5px" fit="cover" :src="imgApi + scope.row.image"
                                :preview-src-list="srcList">
                            </el-image>
                        </template>
                    </el-table-column>
                    <el-table-column prop="position" label="位置" min-width="120" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            <div v-if="scope.row.position === 10">
                                小程序
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="create_time" label="创建时间" min-width="155" align="center">
                        <template slot-scope="scope">
                            {{scope.row.create_time | timeFilter}}
                        </template>
                    </el-table-column>

                    <el-table-column label="操作" min-width="130" align="center">
                        <template slot-scope="scope">
                            <el-button type="text" class="icon-btn" @click="routerChange(2,scope.row)">
                                <el-tooltip placement="top" content="编辑">
                                    <i class="iconfont icon-edit" />
                                </el-tooltip>
                            </el-button>
                            <el-button type="text" class="icon-btn" @click="handleDelete(scope.row.id)">
                                <el-tooltip placement="top" content="删除">
                                    <i class="iconfont icon-delete" />
                                </el-tooltip>
                            </el-button>
                        </template>
                    </el-table-column>
                    <div class="" slot="empty">
                        <no-data></no-data>
                    </div>
                </el-table>
            </div>
            <div class="page-container">
                <el-pagination layout="total, sizes, prev, pager, next" background @size-change="pageSizeChange"
                    @current-change="pageChange" :current-page="currPage" :total="total" :page-size="pageSize">
                </el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
import Core from '@/core';
const USER_TYPE = Core.Const.USER_TYPE;
export default {
    components: {
        NoData: () => import('@/components/Empty.vue')
    },
    props: {},
    data() {
        return {
            userType: Core.Data.getUserType(),
            USER_TYPE: Core.Const.USER_TYPE,
            imgApi: Core.Const.NET.FILE_URL,
            total: 0,
            currPage: 1,
            pageSize: 10,

            searchForm: {
                status: '',
                time: ''
            },
            statusList: [],

            tableData: [],
            srcList: []
        };
    },
    watch: {},
    computed: {},
    mounted() {},
    created() {},
    mounted() {
        this.getTableData();
    },
    methods: {
        auth: Core.Utils.auth,
        pageChange(page) {
            this.currPage = page;
            this.getTableData();
        },
        pageSizeChange(size) {
            this.pageSize = size;
            this.getTableData();
        },
        getTableData() {
            Core.Api.Banner.list(10, this.currPage, this.pageSize).then(
                (res) => {
                    console.log('getTableData res', res);
                    this.total = res.count;
                    this.tableData = res.list;
                    this.tableData.map((item) => {
                        if (item.image) {
                            this.srcList.push(this.imgApi + item.image);
                        }
                        return item;
                    });
                    console.log('this.srcList', this.srcList);
                }
            );
        },
        routerChange(key, item) {
            switch (key) {
                case 1:
                    this.$router.push('/online-set/advertising-edit');
                    break;
                case 2:
                    this.$router.push(`/online-set/advertising-edit?id=${item.id}`);
                    break;
            }
        },
        handleDelete(id) {
            this.$confirm('确定执行删除操作?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                Core.Api.Banner.delete(id).then((res) => {
                    this.$message.success('删除成功!');
                    this.getTableData();
                });
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.container {
    @import '@/common/styles/table.scss';
}
</style>